<template>
  <!-- <div class="LeavEditAbout">
    <div class="notAva">
      <i class="el-icon-warning-outline"></i>暂无相关文档
    </div>
  </div> -->
  <div>
    <docu-about :stateFlag="stateFlag"></docu-about>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { leavAPI } from '@api/modules/leav';
import DocuAbout from '@/views/component/docuCommon/docuAbout';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'LeavEditAbout',
  components: {
    DocuAbout
  },
  data() {
    return {
      stateFlag: 0
    };
  },
  methods: {
    //获取表单信息
    getLeavInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(leavAPI.getLeavById, { leav_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.stateFlag = res.data.data.form.status;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped>
.LeavEditAbout {
  position: relative;
}
@media screen and (min-width: 993px) {
  .LeavEditAbout {
    height: 350px;
  }
}
@media screen and (max-width: 992px) {
  .LeavEditAbout {
    height: 700px;
  }
}
.notAva {
  position: absolute;
  color: #a59e9e;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}
</style>
