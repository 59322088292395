<template>
  <div class="vg_wrapper">
    <div class="vd_edhea vg_button_group">
      <editHeader :btn="btn" :isShow="isShow" :strForm="leavForm" @closeEdit="closeEdit" @openEdit="openEdit" @submit="submit('leavForm')" />
      <div class="vd_export2 vg_mr_8">
        <span style="margin-right: 2px">请假信息单号:</span>
        <el-input size="mini" style="width: 150px" disabled v-model="leavForm.leav_no" show-word-limit> </el-input>
      </div>
    </div>
    <el-form ref="leavForm" :model="leavForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row>
        <el-col :md="8">
          <el-form-item label="单据类型" prop="document_type" required>
            <el-select v-model="leavForm.document_type" placeholder="请选择单据类型" @change="getDocType()" clearable>
              <el-option label="请假单" :value="0"></el-option>
              <el-option label="调休单" :value="1"></el-option>
              <el-option label="外勤/出差" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="leavForm.document_type === 2 ? '类型' : '请假类型'" prop="leav_type" required>
            <el-select v-model="leavForm.leav_type" placeholder="请选择" @change="getLeavType()" clearable>
              <el-option v-for="item in leavTypeGroupOpt" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时段类型" prop="leav_way" required>
            <el-select v-model="leavForm.leav_way" placeholder="请选择时段类型" clearable @change="changeLeavWay">
              <el-option v-for="item in leavWayGroupOpt" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始日期" prop="leav_start_date">
            <template>
              <el-date-picker
                v-model="leavForm.leav_start_date"
                type="date"
                placeholder="请选择开始日期"
                :picker-options="pickerOptionsStart"
                :disabled="dayDisabled"
                @change="checkStartDate"
              >
              </el-date-picker>
            </template>
          </el-form-item>
          <el-form-item label="开始半天" prop="leav_start_day" :rules="[{ required: !disabled00 }]">
            <el-select v-model="leavForm.leav_start_day" :disabled="disabled00" placeholder="请选择起始半天时段">
              <el-option label="上午" :value="0"></el-option>
              <el-option label="下午" :value="0.5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :rules="leavForm.leav_way === 0 ? [{ required: false }] : rules.leav_start_time" label="开始时间" prop="leav_start_time">
            <template>
              <el-time-picker
                placeholder="请选择开始时间"
                v-model="leavForm.leav_start_time"
                format="HH:mm"
                value-format="HH:mm"
                :picker-options="{
                  selectableRange: ['00:00:00 - 11:30:00', '13:00:00 - 23:59:59']
                }"
                :disabled="hourDisabled"
                @change="value => mathHourLeav(value, 1)"
              >
              </el-time-picker>
            </template>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="剩余年假(天)" prop="stff_off_break">
            <el-input v-model="leavForm.stff_off_break" disabled placeholder="根据数据自动填充"></el-input>
          </el-form-item>
          <el-form-item label="剩余调休(时)" prop="stff_off_hour">
            <el-input v-model="leavForm.stff_off_hour" disabled placeholder="根据数据自动填充"></el-input>
          </el-form-item>
          <el-form-item label="剩余旅游假(天)" prop="stff_tour_break">
            <el-input v-model="leavForm.stff_tour_break" disabled placeholder="根据数据自动填充"></el-input>
          </el-form-item>
          <el-form-item label="结束日期" prop="leav_end_date">
            <template>
              <el-date-picker
                v-model="leavForm.leav_end_date"
                type="date"
                placeholder="请选择结束日期"
                :picker-options="pickerOptionsEnd"
                :disabled="dayDisabledEnd"
                @change="checkStartDate"
              >
              </el-date-picker>
            </template>
          </el-form-item>
          <el-form-item label="结束半天" prop="leav_end_day" :rules="[{ required: !disabled00 }]">
            <el-select v-model="leavForm.leav_end_day" :disabled="disabled00" placeholder="请选择结束半天时段">
              <el-option label="上午" :value="0.5"></el-option>
              <el-option label="下午" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="结束时间" prop="leav_end_time" :rules="leavForm.leav_way === 0 ? [{ required: false }] : rules.leav_end_time">
            <template v-if="timeFlag">
              <el-time-picker
                placeholder="请选择结束时间"
                v-model="leavForm.leav_end_time"
                format="HH:mm"
                value-format="HH:mm"
                :picker-options="{
                  selectableRange: timeArray
                }"
                :disabled="hourDisabled"
                @change="mathHourLeav"
              >
              </el-time-picker>
            </template>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="申请员工" prop="leav_stff_name" :rules="{ required: true, message: '请选择员工' }">
            <select-input
              :selTableData="selTableData"
              :tableHeader="staffTableHeader"
              :rowSelect="rowSelect"
              @openSelect="openSelect"
              @getSelTableRow="getSelTableRow"
              :inputVal="leavForm.leav_stff_name"
              :disabled="disabled"
              :isShowCol="false"
              :loading="false"
              placeholder="请选择员工姓名"
            >
            </select-input>
          </el-form-item>
          <el-form-item label="员工公司" prop="cptt_name">
            <el-input v-model="leavForm.leav_cptt_name" disabled placeholder="根据所选员工自动填充"></el-input>
          </el-form-item>
          <el-form-item label="员工部门" prop="dept_name">
            <el-input v-model="leavForm.leav_dept_name" disabled placeholder="根据所选员工自动填充"></el-input>
          </el-form-item>
          <el-form-item label="员工小组" prop="dept_team_name">
            <el-input v-model="leavForm.leav_dept_team_name" disabled placeholder="根据所选员工自动填充"></el-input>
          </el-form-item>
          <el-form-item :label="labelDay" prop="leav_day_diff">
            <el-input v-model="leavForm.leav_day_diff" disabled placeholder="根据请假时间自动填充"></el-input>
          </el-form-item>
          <el-form-item :label="labelHour" prop="leav_hour_diff">
            <el-input v-model="leavForm.leav_hour_diff" disabled placeholder="根据请假时间自动填充"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :rows="1" v-model="leavForm.leav_remark" maxlength="100" placeholder="请填写备注"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row class="vg_mt_16">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { stffAPI } from '@api/modules/staff';
import { leavAPI } from '@api/modules/leav';
import inputUser from '@/views/component/inputUser';
import selectInput from '@/views/component/selectInput';
import { staffTableHeader } from '@/views/component/tableHeader/staffTableHeader';
import options from '@/views/component/common/options';
import editHeader from '@/views/component/editHeader';
import UrlEncode from '@assets/js/UrlEncode';
export default {
  name: 'LeavEditMain',
  components: {
    inputUser,
    selectInput,
    editHeader
  },
  data() {
    return {
      leavForm: {
        leav_id: null,
        document_type: null,
        leav_type: null,
        leav_way: null,
        leav_start_date: null,
        leav_start_day: null,
        leav_start_time: null,
        leav_end_date: null,
        leav_end_day: null,
        leav_end_time: '',
        leav_day_diff: null,
        leav_hour_diff: null,
        stff_off_break: null, // 剩余年假
        stff_off_hour: null,
        leav_remark: '',
        leav_stff_id: null,
        leav_dept_id: null,
        leav_dept_team_id: null,
        leav_stff_name: '',
        leav_cptt_name: '',
        leav_dept_name: '',
        leav_dept_team_name: '',
        status: null,
        stff_this_break: null,
        stff_last_break: null
      },
      rules: {
        leav_start_date: [
          {
            type: 'date',
            required: true,
            message: '请选择开始日期',
            trigger: 'change'
          }
        ],
        leav_end_date: [
          {
            type: 'date',
            required: true,
            message: '请选择结束日期',
            trigger: 'change'
          }
        ],
        leav_start_time: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
        leav_end_time: [{ required: true, message: '请选择结束时间', trigger: 'blur' }]
      },
      stffForm: {
        stff_name: '',
        cptt_name: '',
        dept_name: '',
        dept_team_name: '',
        stff_id: Number,
        cptt_id: Number,
        dept_id: Number,
        dept_team_id: Number,
        stff_this_break: null,
        stff_last_break: null,
        stff_off_hour: null
      },
      rowSelect: [],
      selTableData: [],
      staffTableHeader: [],
      leavTypeGroupOpt: [],
      hourDisabled: false, // 小时关闭器
      dayDisabled: false,
      dayDisabledEnd: false,
      disabled00: true, // 半天
      disabled: true,
      dateFlag: false,
      gapValue: null,
      pickerOptionsStart: {},
      pickerOptionsEnd: {
        disabledDate: time => {
          if (this.leavForm.leav_start_date) {
            return time.getTime() < this.leavForm.leav_start_date;
          }
          return time.getTime() > Date.now() - 8.64e6;
        }
      },
      leavWayGroupOpt: [],
      labelDay: '请假天数',
      labelHour: '请假小时',
      isShow: true,
      btn: {},
      timeArray: ['00:00:00 - 11:30:00', '13:00:00 - 23:59:59'],
      timeFlag: true
    };
  },
  watch: {
    leavForm: {
      deep: true,
      handler: function (val) {
        if (val.leav_start_date && val.leav_end_date) {
          this.mathDaysLeav();
        }
        if ((val.leav_type === 1 && val.leav_way === 0) || (val.leav_type === 0 && val.leav_way === 0)) {
          this.mathHourLeav();
        }
      }
    }
  },
  created() {
    this.initData();
    this.staffTableHeader = staffTableHeader;
  },
  methods: {
    initData() {
      // if (this.$route.query.form_id) {
      this.getLeavInfo();
      // }
    },
    // 获取传递selTableData
    openSelect(val) {
      if (val === true) {
        this.getSelectTable();
      }
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
      // this.disabled = false;
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    // 获取传递selTableData
    getSelectTable() {
      get(stffAPI.getAllStffsV2)
        .then(res => {
          if (res.data.code === 0) {
            this.selTableData = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取双击selTableData的row
    getSelTableRow(val) {
      this.leavForm.leav_cptt_id = val.cptt_id;
      this.leavForm.leav_stff_id = val.stff_id;
      this.leavForm.leav_dept_id = val.dept_id;
      this.leavForm.leav_dept_team_id = val.dept_team_id;
      this.leavForm.leav_stff_name = val.stff_name;
      this.leavForm.leav_cptt_name = val.cptt_name;
      this.leavForm.leav_dept_name = val.dept_name;
      this.leavForm.leav_dept_team_name = val.dept_team_name;
      this.leavForm.stff_off_hour = val.stff_off_hour;
      this.leavForm.stff_off_break = val.stff_this_break + val.stff_last_break;
    },
    // 根据单据类型切换请假类型清空选项
    clearTypeInfo() {
      this.leavForm.leav_type = null;
      this.leavForm.leav_way = null;
      this.leavForm.leav_start_day = null;
      this.leavForm.leav_end_day = null;
      // this.leavForm.leav_start_day = null
      // this.leavForm.leav_end_day = null
      this.disabled00 = true;
    },
    // 根据时段类型更新
    clearLeavWay() {
      this.leavForm.leav_start_time = null;
      this.leavForm.leav_end_time = null;
      this.leavForm.leav_hour_diff = null;
    },
    // 根据请假类型切换清空选项
    clearLeavTypeInfo() {
      this.leavForm.leav_start_day = null;
      this.leavForm.leav_end_day = null;
      this.leavForm.leav_hour_diff = null;
      this.leavForm.leav_start_time = null;
      this.leavForm.leav_end_time = null;
      this.leavForm.leav_way = null;
    },
    // 获取请假类型
    getDocType() {
      this.clearTypeInfo();
      if (this.leavForm.document_type === 1) {
        this.labelDay = '增加天数';
        this.labelHour = '增加小时';
        this.leavTypeGroupOpt = options.leavOpts.filter(item => item.value === 0);
      } else if (this.leavForm.document_type === 0) {
        this.labelDay = '请假天数';
        this.labelHour = '请假小时';
        this.leavTypeGroupOpt = options.leavOpts.filter(item => item.value !== 0);
      } else if (this.leavForm.document_type === 2) {
        this.labelDay = '外勤/出差天数';
        this.labelHour = '外勤/出差小时';
        this.leavTypeGroupOpt = options.leavOpts.filter(item => [12, 13, 14].includes(item.value));
      }
    },
    // 更换请假类型
    getLeavType(val) {
      const newVal = this.leavForm.leav_type;
      this.clearLeavTypeInfo();
      if (newVal < 2 || [3, 11, 12, 13, 14].includes(newVal)) {
        this.leavForm.leav_way = null;
        this.leavWayGroupOpt = [
          { value: 0, label: '按天数' },
          { value: 1, label: '按小时' }
        ];
      } else if (newVal === 2 || newVal >= 4) {
        this.leavForm.leav_way = 0;
        this.dayDisabledEnd = false;
        this.leavWayGroupOpt = [{ value: 0, label: '按天数' }];
      }
      // (半天等) 年假和丧假处理
      if (newVal === 2 || newVal === 7) {
        this.dateFlag = true;
        this.leavForm.leav_start_day = 0;
        this.leavForm.leav_end_day = 1;
        this.disabled00 = false;
        this.hourDisabled = true;
      } else {
        this.dateFlag = false;
        this.disabled00 = true;
      }
      //病假可以选择半天
      if (newVal === 4) {
        this.dateFlag = true;
        this.disabled00 = false;
      }
    },
    // 切换类型(时段类型)
    changeLeavWay(val) {
      if (val === 1) {
        this.hourDisabled = false;
        this.dayDisabledEnd = true;
        this.leavForm.leav_end_date = this.leavForm.leav_start_date;
      } else if (val === 0) {
        this.hourDisabled = true;
        this.dayDisabledEnd = false;
        this.clearLeavWay();
      }
    },
    // 选择开始日期 调休单，结束日期等于开始日期
    checkStartDate(newVal) {
      if (this.leavForm.leav_way === 1) {
        this.leavForm.leav_end_date = newVal;
        this.mathDaysLeav();
      }
      this.leavForm.leav_start_date = new Date(this.leavForm.leav_start_date);
      this.leavForm.leav_end_date = new Date(this.leavForm.leav_end_date);

      let strTime = this.leavForm.leav_start_date ? this.leavForm.leav_start_date.getTime() : '';
      let endTime = this.leavForm.leav_end_date ? this.leavForm.leav_end_date.getTime() : '';
      if (strTime && endTime) {
        if (strTime > endTime) {
          this.leavForm.leav_end_date = '';
          this.$message.warning('开始日期不可早于结束日期');
        }
      }
    },

    // 计算天数差
    mathDaysLeav() {
      if (this.dateFlag === true) {
        const leavDate = new Date(this.leavForm.leav_end_date) - new Date(this.leavForm.leav_start_date);
        const leavDays = Math.floor(leavDate / (24 * 3600 * 1000)) - (this.leavForm.leav_start_day - this.leavForm.leav_end_day);
        this.leavForm.leav_day_diff = leavDays;
      } else {
        const leavDate = new Date(this.leavForm.leav_end_date) - new Date(this.leavForm.leav_start_date);
        const leavDays = Math.floor(leavDate / (24 * 3600 * 1000)) + 1;
        this.leavForm.leav_day_diff = leavDays;
      }
    },
    // 计算小时差
    mathHourLeav(e, val) {
      if (this.leavForm.leav_start_time) {
        if (this.leavForm.leav_start_time === '11:30') {
          this.timeArray = ['13:01:00 - 23:59:59'];
        } else if (this.leavForm.leav_start_time === '11:29') {
          this.timeArray = ['11:30:00 - 11:30:00', '13:00:00 - 23:59:59'];
        } else {
          this.timeArray = [this.leavForm.leav_start_time + ':00 - 11:30:00', '13:00:00 - 23:59:59'];
        }
        console.log('this.timeArray', this.timeArray);
        if (val === 1) {
          this.leavForm.leav_end_time = null;
        }
      }
      if (this.leavForm.leav_start_time && this.leavForm.leav_end_time) {
        let shour = Number(this.leavForm.leav_start_time.split(':')[0]);
        let ehour = Number(this.leavForm.leav_end_time.split(':')[0]);
        let sminu = Number(this.leavForm.leav_start_time.split(':')[1]);
        let eminu = Number(this.leavForm.leav_end_time.split(':')[1]);
        const st = parseInt((shour + sminu / 60) * 10000);
        const en = parseInt((ehour + eminu / 60) * 10000);
        const diff = (en - st) / 10000;
        this.gapValue = Math.ceil(diff * 2) / 2;
        // this.gapValue = Math.round(((Number(ehour) + (Number(eminu) / 60)) - (Number(shour) + (Number(sminu) / 60)))*2)/2
        if (shour < 12 && ehour >= 13) {
          this.gapValue = this.gapValue - 1.5;
        }
        if (this.leavForm.leav_type === 0 && Math.abs(diff) % 0.5 !== 0) {
          this.gapValue = this.gapValue - 0.5;
        }
        // if (this.leavForm.leav_type === 0 || this.leavForm.leav_type === 1) {
        //   if (this.gapValue >= 7) {
        //     this.gapValue = 8
        //   }
        // }
        if (this.leavForm.document_type === 0 && this.leavForm.leav_type === 1 && this.leavForm.leav_way === 1) {
          let isOne = this.gapValue < 1;
          if (isOne) this.$message.warning('调休时间起始不能小于一小时!');
          this.leavForm.leav_hour_diff = isOne ? 1 : this.gapValue;
        } else {
          this.leavForm.leav_hour_diff = this.gapValue;
        }
      } else if (this.leavForm.leav_start_date && this.leavForm.leav_end_date) {
        this.leavForm.leav_hour_diff = this.leavForm.leav_day_diff * 8;
      }
    },
    //获取表单信息
    getLeavInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.leavForm.leav_id = props.form_id;
      get(leavAPI.getLeavById, { leav_id: this.leavForm.leav_id })
        .then(res => {
          if (res.data.code === 0) {
            this.leavForm = res.data.data.form;
            this.leavForm.stff_off_break = this.leavForm.stff_this_break + this.leavForm.stff_last_break;
            this.leavForm.leav_start_date = this.leavForm.leav_start_date * 1000;
            this.leavForm.leav_end_date = this.leavForm.leav_end_date * 1000;
            this.leavTypeGroupOpt = options.leavOpts;
            this.btn = res.data.data.btn;
            this.leavWayGroupOpt = [
              { value: 0, label: '按天数' },
              { value: 1, label: '按小时' }
            ];
            if (this.leavForm.leav_type === 0) {
              this.leavForm.document_type = 1;
              this.leavTypeGroupOpt = options.leavOpts.filter(item => item.value === 0);
            } else if ([12, 13, 14].includes(this.leavForm.leav_type)) {
              this.leavForm.document_type = 2;
              this.leavTypeGroupOpt = options.leavOpts.filter(item => [12, 13, 14].includes(item.value));
            } else {
              this.leavForm.document_type = 0;
              this.leavTypeGroupOpt = options.leavOpts.filter(item => item.value !== 0);
            }
            this.getInputUser();
            this.isDaysOrHour();
            this.isHour();
            if (this.leavForm.document_type === 1) {
              this.labelDay = '增加天数';
              this.labelHour = '增加小时';
            }
            if (this.leavForm.document_type === 2) {
              this.labelDay = '外勤/出差天数';
              this.labelHour = '外勤/出差小时';
            }
            let timeTemp = this.leavForm.leav_end_time.split(':');
            if (Number(timeTemp[0]) === 11) {
              if (Number(timeTemp[1]) > 30) {
                this.leavForm.leav_end_time = '11:30';
              }
            } else if (Number(timeTemp[0]) === 12) {
              this.leavForm.leav_end_time = '11:30';
            }
            // this.getLeavType(1)
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取时判断天数还是小时类型
    isDaysOrHour() {
      let newVal = this.leavForm.leav_type;
      if (newVal < 2 || newVal === 3 || newVal === 11) {
        this.leavWayGroupOpt = [
          { value: 0, label: '按天数' },
          { value: 1, label: '按小时' }
        ];
      } else if (newVal === 2 || newVal >= 4) {
        this.leavForm.leav_way = 0;
        this.dayDisabledEnd = false;
        this.hourDisabled = true;
        this.leavWayGroupOpt = [{ value: 0, label: '按天数' }];
      }
      if (newVal === 2 || newVal === 7 || newVal === 4) {
        this.dateFlag = true;
        this.disabled00 = false;
        this.hourDisabled = true;
      } else {
        this.dateFlag = false;
        this.disabled00 = true;
      }
    },
    // 获取判断小时类型是否开启半天
    isHour() {
      let newVal = this.leavForm.leav_way;
      if (newVal === 1) {
        this.dayDisabledEnd = true;
        this.hourDisabled = false;
        this.leavForm.leav_start_day = null;
        this.leavForm.leav_end_day = null;
      } else {
        this.dayDisabledEnd = false;
        this.hourDisabled = true;
        if (this.leavForm.leav_type !== 2 && this.leavForm.leav_type !== 4 && this.leavForm.leav_type !== 7) {
          this.leavForm.leav_start_day = null;
          this.leavForm.leav_end_day = null;
        }
      }
    },

    //获取录入人
    getInputUser() {
      this.stffForm.dept_name = this.leavForm.dept_name;
      this.stffForm.dept_team_name = this.leavForm.dept_team_name;
      this.stffForm.stff_name = this.leavForm.stff_name;
    },
    //提交表单
    submit(formName) {
      if (this.leavForm.leav_type === 1 && Number(this.leavForm.stff_off_hour) === 0) return this.$message.warning('剩余调休不足');
      if (this.leavForm.leav_type === 2 && Number(this.leavForm.stff_off_break) === 0) return this.$message.warning('剩余年假不足');
      if (this.leavForm.leav_type === 2 && Number(this.leavForm.stff_off_break) < Number(this.leavForm.leav_day_diff))
        return this.$message.warning('剩余年假不足');
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              if (this.leavForm.leav_hour_diff <= 0 && this.leavForm.leav_way == 1) {
                this.$message({
                  type: 'error',
                  message: '请假时间不可以小于半个小时'
                });
                return false;
              }
              if (this.leavForm.leav_way == 0 && this.leavForm.leav_day_diff == 0) {
                this.$message({ type: 'error', message: '请正确选择时间段' });
                return;
              }
              if (this.leavForm.leav_type === 2) {
                if (this.leavForm.leav_start_day === null || this.leavForm.leav_end_day === null) {
                  let mg = '年假请选择半天';
                  let tp = 'warning';
                  this.$message({ message: mg, type: tp });
                  return false;
                }
                if (this.leavForm.leav_day_diff > this.leavForm.stff_off_break) {
                  let mg = '请假天数不能超过剩余年假天数';
                  let tp = 'warning';
                  this.$message({ message: mg, type: tp });
                  return false;
                }
              } else if (this.leavForm.leav_type === 1) {
                if (this.leavForm.leav_way === 0) {
                  this.leavForm.leav_hour_diff = this.leavForm.leav_day_diff * 8;
                  // this.leavForm.leav_day_diff = null;
                  if (this.leavForm.leav_hour_diff > this.leavForm.stff_off_hour) {
                    let mg = '请假小时不能超过剩余调休小时';
                    let tp = 'warning';
                    this.$message({ message: mg, type: tp });
                    return false;
                  }
                } else if (this.leavForm.leav_way === 1) {
                  if (this.leavForm.leav_hour_diff >= 7) {
                    this.leavForm.leav_hour_diff = 8;
                  }
                  if (this.leavForm.leav_hour_diff > this.leavForm.stff_off_hour) {
                    let mg = '请假小时不能超过剩余调休小时';
                    let tp = 'warning';
                    this.$message({ message: mg, type: tp });
                    return false;
                  }
                }
              } else if (this.leavForm.leav_type === 6 || this.leavForm.leav_type === 9) {
                if (this.leavForm.leav_day_diff > 10) {
                  let mg = '婚假与陪产假不能超过10天';
                  let tp = 'warning';
                  this.$message({ message: mg, type: tp });
                  return false;
                }
              }
              // else if (this.leavForm.leav_type === 0 && this.leavForm.leav_way === 1) {
              //   if (this.leavForm.leav_hour_diff >= 7) {
              //     this.leavForm.leav_hour_diff = 8;
              //   }
              // }
              this.saveInfo();
            } else {
              if (this.leavForm.leav_stff_name === '') {
                let mg = '请选择申请员工';
                let tp = 'warning';
                this.$message({ message: mg, type: tp });
              }
              return false;
            }
          });
        })
        .catch(() => {
          this.$message.info('已取消保存');
        });
    },
    //保存
    saveInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.leavForm.leav_id = props.form_id;
      const leavFormCopy = this.leavForm;
      leavFormCopy.leav_start_date = this.leavForm.leav_start_date / 1000;
      leavFormCopy.leav_end_date = this.leavForm.leav_end_date / 1000;
      leavFormCopy.personal = this.$cookies.get('userInfo').personal;
      post(leavAPI.editLeav, leavFormCopy).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button {
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
//.vd_button_group {
//  padding-bottom: 16px;
//  margin-bottom: 32px;
//  border-bottom: 1px solid $color-secondary-text;
//}
.vg_wrapper ::v-deep.el-form-item {
  margin: 0;
  padding: 0;
}
.vg_wrapper ::v-deep .el-table td,
::v-deep.el-table th {
  padding: 0;
}

.vg_wrapper ::v-deep .el-table .cell {
  padding: 0;
}
::v-deep.is-disabled .el-input__inner {
  cursor: default;
  color: $color-conventional-text;
}
.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 120px;
}
</style>
